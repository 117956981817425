    class MorphingBG {
        constructor(el) {
            this.DOM = {};
            this.DOM.el = el;
            this.DOM.paths = Array.from(this.DOM.el.querySelectorAll('path'));
            this.animate();
        }
        animate() {
            this.DOM.paths.forEach((path) => {
                setTimeout(() => {
                    anime({
                        targets: path,
                        duration: anime.random(4000, 6000),
                        easing: [0.5,0,0.5,1],
                        d: path.getAttribute('pathdata:id'),
                        loop: true,
                        direction: 'alternate'
                    });
                }, anime.random(0,1000));
            });
        }
    };

        class MorphingBG2 {
        constructor(el) {
            this.DOM = {};
            this.DOM.el = el;
            this.DOM.paths = Array.from(this.DOM.el.querySelectorAll('path'));
            this.animate();
        }
        animate() {
            this.DOM.paths.forEach((path) => {
                // console.log('fart');
                setTimeout(() => {
                    anime({
                        targets: path,
                        duration: anime.random(3000, 4000),
                        easing: [0.5,0,0.5,1],
                        d: path.getAttribute('pathdata:id'),
                        loop: true,
                        direction: 'alternate'
                    });
                }, anime.random(0,1000));
            });
        }
    };

    new MorphingBG(document.querySelector('#wave .scene'));

    new MorphingBG2(document.querySelector('#wave2 .scene'));


    

$( document ).ready(function() {
  
    
    // var rellax = new Rellax('.rellax');

    $('.openPrivacy').on('click',function(){
        $('.modal-header').text('Privacy Policy');
        $('.modal-body').load('privacy.html',function(){
            $('#modal').modal({show:true});
        });
    });
    $('.openTerms').on('click',function(){
        $('.modal-header').text('Terms & Conditions');
        $('.modal-body').load('terms.html',function(){
            $('#modal').modal({show:true});
        });
    });
   

    var typed = new Typed('.typed', {
    strings: ['focus', 'emotion regulation', 'memory', 'learning', 'decision making', 'resilience', 'creativity', 'concentration'],
    typeSpeed: 45,
    loop: true
    });




});

$(window).on("load",function(){
  $('body').addClass("loaded");
});

